// Vue.js components
import {
    createApp
} from 'vue';

import {
    MediaLibraryAttachment
} from '@spatie/media-library-pro-vue3-attachment';
import {
    MediaLibraryCollection
} from '@spatie/media-library-pro-vue3-collection';

const app = createApp({
    components: {
        MediaLibraryAttachment,
        MediaLibraryCollection,
    }
});

app.mount('#app');
